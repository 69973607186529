
const messageUtils = {
  components: {},
  data() {
    return {
      errors: {}
    }
  },
  methods: {
    clearMessage(field) {
      if (field) {
        this.$delete(this.errors, field)
      } else {
        this.errors = {}
      }
    },
    updateMessage(status, data) {
      this.clearMessage()

      if (this.isErrorStatus(status)) {
        const errors = {}
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            const mes = data[key][0]
            errors[key] = mes
          } else if (typeof (data[key]) === 'object') {
            errors[key] = data[key][Object.keys(data[key])[0]][0]
          }
        })
        if (data['non_field_errors']) errors['message'] = data['non_field_errors'][0]
        this.errors = errors
      } else {
        // TODO
      }
    },
    isErrorStatus(status) {
      return parseInt(status) >= 400
    }
  }
}

export default messageUtils
